// extracted by mini-css-extract-plugin
export var galleryMasonryImageWrapper = "D_jQ d_jQ d_cv";
export var galleryMasonryImage = "D_jP d_jP d_w d_bR d_dB";
export var alignLeft = "D_rd d_fp d_bG d_dv";
export var alignCenter = "D_bP d_fq d_bD d_dw";
export var alignRight = "D_rf d_fr d_bH d_dx";
export var galleryContainer = "D_tS d_dW";
export var galleryContainerFull = "D_tT d_dT";
export var galleryRowWrapper = "D_tV d_cc";
export var galleryGuttersImage = "D_jS d_jS d_K d_cv";
export var galleryNoGuttersImage = "D_jR d_jR d_K d_cD";
export var galleryTextGutters = "D_jM d_jM d_cw";
export var galleryTextNoGutters = "D_jN d_jN d_cw";
export var galleryTextMasonry = "D_tW d_jM d_cw";
export var galleryImageWrapper = "D_tX d_fg d_Z";
export var descText = "D_tY d_jT d_0 d_9 d_7 d_8 d_n";
export var guttersDesc = "D_tZ";