// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "N_wG d_bD";
export var storyRowWrapper = "N_hx d_hx d_bK";
export var storyLeftWrapper = "N_wH d_bz d_bP";
export var storyWrapperFull = "N_wJ d_cD";
export var storyWrapperFullLeft = "N_qZ d_cD d_bz d_bP d_bD";
export var contentWrapper = "N_mS d_hy";
export var storyLeftWrapperCenter = "N_wK d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "N_wL d_hF";
export var storyHeader = "N_wM d_hD d_w d_cs";
export var storyHeaderCenter = "N_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "N_hB d_hB d_by d_dw";
export var storyBtnWrapper = "N_wN d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "N_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "N_rj d_fg d_Z";
export var imageWrapperFull = "N_wP d_w d_H d_bf d_Z";
export var SubtitleSmall = "N_pd K_pd K_vB K_vN";
export var SubtitleNormal = "N_pf K_pf K_vB K_vP";
export var SubtitleLarge = "N_pg K_pg K_vB K_vQ";
export var textLeft = "N_dv";
export var textCenter = "N_dw";
export var textRight = "N_dx";