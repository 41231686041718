// extracted by mini-css-extract-plugin
export var alignLeft = "J_rd d_fp d_bG d_dv";
export var alignCenter = "J_bP d_fq d_bD d_dw";
export var alignRight = "J_rf d_fr d_bH d_dx";
export var menuContainer = "J_vy d_dW";
export var menuContainerFull = "J_vz d_dT";
export var menuMainHeader = "J_jC d_jC d_w d_c3";
export var menuComponentWrapper = "J_jG d_jG d_cv";
export var menuComponentWrapperDesign2 = "J_jH d_jH d_cy";
export var menuComponentText = "J_jD d_jD d_cn";
export var menuComponentTextDesign2 = "J_jF d_jF d_cs";
export var menuImageWrapperDesign2 = "J_jJ d_jJ d_w d_Z";