// extracted by mini-css-extract-plugin
export var customText = "x_sV d_dv d_cs d_cg";
export var videoIframeStyle = "x_nS d_d5 d_w d_H d_by d_b1 d_R";
export var customImageWrapper = "x_rh d_cs d_cg d_Z";
export var customRow = "x_pb d_bD d_bf";
export var quoteWrapper = "x_gQ d_bC d_bP d_cs d_cg";
export var quoteBar = "x_nX d_H";
export var masonryImageWrapper = "x_n3";
export var title = "x_r6";
export var Title3Small = "x_sW K_sW K_vB K_vK";
export var Title3Normal = "x_sX K_sX K_vB K_vL";
export var Title3Large = "x_sY K_sY K_vB K_vM";