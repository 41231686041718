// extracted by mini-css-extract-plugin
export var noPadding = "P_fj d_fj";
export var videoFrameWrapper = "P_wQ d_kS d_b5";
export var videoIframeStyle = "P_nS d_d5 d_w d_H d_by d_b1 d_R";
export var videoLayoutRight = "P_wR d_kR d_bK";
export var videoInnerWrapper = "P_wS d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5";
export var videoText = "P_wT d_dw d_w";
export var videoInnerInnerWrapper = "P_wV d_bz d_bD d_bJ";
export var btnWrapper = "P_d2 d_w d_bz d_bD d_cj";
export var btn = "P_mY d_b1 d_bz d_bD d_bP d_cH d_b6 d_dB d_n K_wd K_v4";
export var icon = "P_n9 d_cC";
export var textLeft = "P_dv";
export var textCenter = "P_dw";
export var textRight = "P_dx";