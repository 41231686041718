// extracted by mini-css-extract-plugin
export var alignLeft = "v_rd d_fp d_bG d_dv";
export var alignCenter = "v_bP d_fq d_bD d_dw";
export var alignRight = "v_rf d_fr d_bH d_dx";
export var container = "v_mT d_dW d_Z";
export var containerFull = "v_sw d_dT d_Z";
export var title = "v_r6 d_cr";
export var text = "v_pc d_cx";
export var col = "v_sx d_bz";
export var link = "v_mZ d_w d_H";
export var card = "v_sy d_bz d_bD d_bN d_bJ d_H d_dw d_b5 d_Z";
export var bgImage = "v_sz d_bd d_0 d_8 d_7 d_4 d_9 d_bk";
export var overlay = "v_sB d_bd d_0 d_8 d_7 d_4 d_9";
export var content = "v_sC d_bz d_bD d_bJ";
export var iconBG = "v_sD d_b7 d_bz d_bD d_bP d_cr d_cG";
export var icon = "v_n9";
export var cardTitle = "v_sF d_w d_cs";
export var cardText = "v_sG d_w";
export var SubtitleSmall = "v_pd K_pd K_vB K_vN";
export var SubtitleNormal = "v_pf K_pf K_vB K_vP";
export var SubtitleLarge = "v_pg K_pg K_vB K_vQ";