// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "F_t0 d_gv d_cs";
export var heroHeaderCenter = "F_gw d_gw d_cs d_dw";
export var heroHeaderRight = "F_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "F_t1 d_gr d_cw";
export var heroParagraphCenter = "F_gs d_gs d_cw d_dw";
export var heroParagraphRight = "F_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "F_t2 d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "F_t3 d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "F_t4 d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "F_t5 d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "F_t6 d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "F_t7 K_t7";
export var heroExceptionNormal = "F_t8 K_t8";
export var heroExceptionLarge = "F_t9 K_t9";
export var Title1Small = "F_vb K_vb K_vB K_vC";
export var Title1Normal = "F_vc K_vc K_vB K_vD";
export var Title1Large = "F_vd K_vd K_vB K_vF";
export var BodySmall = "F_s7 K_s7 K_vB K_vV";
export var BodyNormal = "F_s8 K_s8 K_vB K_vW";
export var BodyLarge = "F_s9 K_s9 K_vB K_vX";