// extracted by mini-css-extract-plugin
export var carouselContainer = "S_sm d_w d_H d_bf d_Z";
export var carouselContainerCards = "S_xt S_sm d_w d_H d_bf d_Z";
export var carouselContainerSides = "S_xv d_w d_H d_Z";
export var prevCarouselItem = "S_xw d_w d_H d_0 d_k";
export var prevCarouselItemL = "S_xx S_xw d_w d_H d_0 d_k";
export var moveInFromLeft = "S_xy";
export var prevCarouselItemR = "S_xz S_xw d_w d_H d_0 d_k";
export var moveInFromRight = "S_xB";
export var selectedCarouselItem = "S_xC d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "S_xD S_xC d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "S_xF S_xC d_w d_H d_Z d_bf";
export var nextCarouselItem = "S_xG d_w d_H d_0 d_k";
export var nextCarouselItemL = "S_xH S_xG d_w d_H d_0 d_k";
export var nextCarouselItemR = "S_xJ S_xG d_w d_H d_0 d_k";
export var arrowContainer = "S_xK d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "S_xL S_xK d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "S_xM S_xL S_xK d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "S_xN S_xK d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "S_xP";
export var nextArrowContainerHidden = "S_xQ S_xN S_xK d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "S_kG d_0";
export var prevArrow = "S_xR S_kG d_0";
export var nextArrow = "S_xS S_kG d_0";
export var carouselIndicatorContainer = "S_xT d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "S_xV d_w d_bz d_bF";
export var carouselText = "S_xW d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "S_xX S_xW d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "S_xY d_b7";
export var carouselIndicator = "S_xZ S_xY d_b7";
export var carouselIndicatorSelected = "S_x0 S_xY d_b7";
export var arrowsContainerTopRight = "S_x1 d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "S_x2 d_0 d_bl d_bC";
export var arrowsContainerSides = "S_x3 d_0 d_bl d_bC";
export var smallArrowsBase = "S_x4 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "S_x5 S_x4 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "S_x6 S_x5 S_x4 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "S_x7 S_x4 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "S_x8 S_x7 S_x4 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "S_n9";
export var iconSmall = "S_x9";
export var multipleWrapper = "S_yb d_bC d_bF d_bf";
export var multipleImage = "S_yc d_bC";
export var sidesPrevContainer = "S_yd S_x5 S_x4 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "S_yf S_x5 S_x4 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";