// extracted by mini-css-extract-plugin
export var alignDiscLeft = "C_tq d_fp d_bG d_dv";
export var alignLeft = "C_rd d_fp d_bG d_dv";
export var alignDiscCenter = "C_tr d_fq d_bD d_dw";
export var alignCenter = "C_bP d_fq d_bD d_dw";
export var alignDiscRight = "C_ts d_fr d_bH d_dx";
export var alignRight = "C_rf d_fr d_bH d_dx";
export var footerContainer = "C_tt d_dW d_bW";
export var footerContainerFull = "C_tv d_dT d_bW";
export var footerHeader = "C_kf d_kf";
export var footerTextWrapper = "C_tw d_w";
export var footerDisclaimerWrapper = "C_km d_km d_ck";
export var badgeWrapper = "C_tx d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "C_ty d_bz d_bJ d_bN d_bL";
export var wide = "C_tz d_cy";
export var right = "C_tB d_bK";
export var line = "C_fk d_fl d_cv";
export var badgeDisclaimer = "C_tC d_bC d_bP d_bJ";
export var badgeContainer = "C_tD d_bz d_bH d_bP";
export var badge = "C_tF";
export var padding = "C_tG d_c7";
export var end = "C_tH d_bH";
export var linkWrapper = "C_tJ d_dB";
export var link = "C_mZ d_dB";
export var colWrapper = "C_tK d_cx";
export var consent = "C_f d_f d_bC d_bP";
export var disclaimer = "C_tL d_bz d_bJ";
export var media = "C_tM d_bx d_dy";
export var itemRight = "C_tN";
export var itemLeft = "C_tP";
export var itemCenter = "C_tQ";
export var exceptionWeight = "C_tR K_v2";