// extracted by mini-css-extract-plugin
export var campaignContainer = "s_r0 d_dW";
export var campaignContainerFull = "s_r1 d_dT";
export var wrapper = "s_r2 d_w";
export var leftWrapper = "s_r3 d_bz d_bN";
export var rightWrapper = "s_r4";
export var subtitle = "s_r5";
export var title = "s_r6";
export var text = "s_pc d_cs";
export var inlineWrapper = "s_r7 d_bC d_bG d_w";
export var btnsWrapper = "s_r8 s_r7 d_bC d_bG d_w d_bJ";
export var priceWrapper = "s_r9 s_r7 d_bC d_bG d_w d_ch d_bP";
export var priceHeading = "s_sb";
export var disclaimerWrapper = "s_sc d_cD";
export var mediaSlot = "s_sd";
export var listWrapper = "s_sf d_bC d_bP d_w";
export var btnWrapper = "s_d2";
export var imageWrapper = "s_rj d_fg d_Z";
export var SmallSmall = "s_sg K_sg K_vB K_vY";
export var SmallNormal = "s_sh K_sh K_vB K_vZ";
export var SmallLarge = "s_sj K_sj K_vB K_vW";
export var textLeft = "s_dv";
export var textCenter = "s_dw";
export var textRight = "s_dx";
export var alignLeft = "s_rd";
export var alignCenter = "s_bP";
export var alignRight = "s_rf";