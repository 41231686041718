// extracted by mini-css-extract-plugin
export var alignLeft = "y_rd d_bG";
export var alignCenter = "y_bP d_bD";
export var alignRight = "y_rf d_bH";
export var textAlignLeft = "y_sZ";
export var textAlignCenter = "y_s0";
export var textAlignRight = "y_s1";
export var embedInnerWrapperDesign1 = "y_s2 d_bM";
export var embed = "y_s3 d_b1";
export var titleMargin = "y_s4 d_cw d_dw";
export var subtitleMargin = "y_s5 d_cs d_dw";
export var paragraphMargin = "y_s6 d_cw d_dw";
export var SubtitleSmall = "y_pd K_pd K_vB K_vN";
export var SubtitleNormal = "y_pf K_pf K_vB K_vP";
export var SubtitleLarge = "y_pg K_pg K_vB K_vQ";
export var BodySmall = "y_s7 K_s7 K_vB K_vV";
export var BodyNormal = "y_s8 K_s8 K_vB K_vW";
export var BodyLarge = "y_s9 K_s9 K_vB K_vX";