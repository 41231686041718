// extracted by mini-css-extract-plugin
export var tileContent = "G_vf d_w d_H d_Z";
export var teamTextLeft = "G_vg d_dv";
export var teamTextCenter = "G_vh d_dw";
export var teamTextRight = "G_vj d_dx";
export var alignLeft = "G_rd d_w d_bz d_fp d_bG d_dv";
export var alignCenter = "G_bP d_w d_bz d_fq d_bD d_dw";
export var alignRight = "G_rf d_w d_bz d_fr d_bH d_dx";
export var teamContainer = "G_vk d_dW";
export var teamContainerFull = "G_vl d_dT";
export var teamRowWrapper = "G_vm d_cc";
export var teamTileWrapper = "G_j3 d_j3 d_Z d_cv";
export var teamTileSquareWrapper = "G_vn d_j4 d_Z d_cv";
export var teamTileRoundWrapper = "G_j4 d_j4 d_Z d_cv";
export var teamTileNoGuttersWrapper = "G_j5 d_j5 d_Z";
export var teamHoverNoGutters = "G_j6 d_j6 d_0 d_w d_H d_bz d_bD d_bP d_bm";
export var teamImageText = "G_kc d_kc d_bl d_w d_by";
export var teamInfoWrapperSquare = "G_vp d_j7";
export var teamInfoWrapperRound = "G_j7 d_j7";
export var teamInfoWrapper = "G_vq d_j8 d_0";
export var teamInfoWrapperNoGutters = "G_j9 d_j9 d_w d_H d_bD d_bM d_bJ d_c7";
export var teamImgWrapper = "G_jZ d_jZ";
export var teamImgWrapperAlt = "G_j0 d_j0";
export var teamImgWrapperNoGutters = "G_kb d_kb";
export var teamHeader = "G_vr d_cw";
export var teamHeaderAlt = "G_vs d_cw";
export var teamHeaderNoGutters = "G_vt d_cw d_cD";